"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OKXCONNECTOR_STORAGE_CIPHER_METHOD = exports.OKXCONNECTOR_STORAGE_CRYPTO_CIPHERIV = exports.OKXCONNECTOR_STORAGER_CRYPTO_SALT = exports.OKXCONNECTOR_STORAGE_CRYPTO_PASSWORD = exports.OKXCONNECTOR_TON_KEY = exports.OKXCONNECTOR_CRYPTO_SENDER_PK_CACHE = exports.OKXCONNECTOR_TOKEN_CACHE = exports.OKXCONNECTOR_JWT_KEY = exports.OKXCONNECTOR_CRYPTO_KEY = exports.OKXCONNECTOR_CACHE_KEY = void 0;
exports.OKXCONNECTOR_CACHE_KEY = "ck";
exports.OKXCONNECTOR_CRYPTO_KEY = "cck_k";
exports.OKXCONNECTOR_JWT_KEY = "cjk_k";
exports.OKXCONNECTOR_TOKEN_CACHE = "ct";
exports.OKXCONNECTOR_CRYPTO_SENDER_PK_CACHE = "csp";
exports.OKXCONNECTOR_TON_KEY = "OKXStorage_ctc";
exports.OKXCONNECTOR_STORAGE_CRYPTO_PASSWORD = "scp";
exports.OKXCONNECTOR_STORAGER_CRYPTO_SALT = "scs";
exports.OKXCONNECTOR_STORAGE_CRYPTO_CIPHERIV = "scpc";
exports.OKXCONNECTOR_STORAGE_CIPHER_METHOD = "aes-256-cbc";
