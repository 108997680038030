"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BridgeConnectionStorage = void 0;
const okx_connect_error_1 = require("../../../core/protocol/error/okx-connect.error");
const log_1 = require("../../../core/utils/log");
class BridgeConnectionStorage {
    constructor(storage) {
        this.storage = storage;
        this.storeKey = 'ton_connect_info';
    }
    storeConnection(connection) {
        return __awaiter(this, void 0, void 0, function* () {
            (0, log_1.logDebug)("BridgeConnectionStorage ==>> storeConnection ==>> connection:", connection);
            return this.storage.saveContent(JSON.stringify(connection), this.storeKey);
        });
    }
    removeConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            (0, log_1.logDebug)("BridgeConnectionStorage ==>> removeConnection");
            return this.storage.clearCache(this.storeKey);
        });
    }
    getConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const stored = this.storage.getItem(this.storeKey);
            (0, log_1.logDebug)("BridgeConnectionStorage ==>> getConnection ==>>this.storage: ", this.storage);
            if (!stored || !(stored === null || stored === void 0 ? void 0 : stored.content)) {
                return null;
            }
            try {
                const content = stored === null || stored === void 0 ? void 0 : stored.content;
                if (content) {
                    let connection;
                    connection = JSON.parse(content);
                    (0, log_1.logDebug)("BridgeConnectionStorage ==>> getConnection ==>>connection: ", connection);
                    return connection;
                }
                else {
                    return null;
                }
            }
            catch (error) {
                return null;
            }
        });
    }
    getHttpConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = yield this.getConnection();
            if (!connection) {
                throw new okx_connect_error_1.OkxConnectError(okx_connect_error_1.OKX_CONNECT_ERROR_CODES.UNKNOWN_ERROR, 'Trying to read HTTP connection source while nothing is stored');
            }
            return connection;
        });
    }
    storeLastWalletEventId(id) {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = yield this.getConnection();
            if (connection && ('connectEvent' in connection)) {
                connection.lastWalletEventId = id;
                return this.storeConnection(connection);
            }
        });
    }
    getLastWalletEventId() {
        return __awaiter(this, void 0, void 0, function* () {
            const connection = yield this.getConnection();
            if (connection && 'lastWalletEventId' in connection) {
                return connection.lastWalletEventId;
            }
            return undefined;
        });
    }
}
exports.BridgeConnectionStorage = BridgeConnectionStorage;
