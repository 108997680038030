"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TonProofService = void 0;
const buffer_1 = require("buffer");
const tweetnacl_1 = require("tweetnacl");
const ton_core_1 = require("ton-core");
const ton_crypto_1 = require("ton-crypto");
const tonProofPrefix = 'ton-proof-item-v2/';
const tonConnectPrefix = 'ton-connect';
class TonProofService {
    /**
     * Reference implementation of the checkProof method:
     * https://github.com/ton-blockchain/ton-connect/blob/main/requests-responses.md#address-proof-signature-ton_proof
     */
    checkProof(payload) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                console.log("checkProof  ===> start  ");
                const stateInit = (0, ton_core_1.loadStateInit)(ton_core_1.Cell.fromBase64(payload.proof.state_init).beginParse());
                // 2.2. Check that TonAddressItemReply.publicKey equals to obtained public key
                const publicKey = buffer_1.Buffer.from(payload.public_key, 'hex');
                // 2.3. Check that TonAddressItemReply.walletStateInit.hash() equals to TonAddressItemReply.address. .hash() means BoC hash.
                const wantedAddress = ton_core_1.Address.parse(payload.address);
                const address = (0, ton_core_1.contractAddress)(wantedAddress.workChain, stateInit);
                if (!address.equals(wantedAddress)) {
                    return false;
                }
                const message = {
                    workchain: address.workChain,
                    address: address.hash,
                    domain: {
                        lengthBytes: payload.proof.domain.lengthBytes,
                        value: payload.proof.domain.value,
                    },
                    signature: buffer_1.Buffer.from(payload.proof.signature, 'base64'),
                    payload: payload.proof.payload,
                    stateInit: payload.proof.state_init,
                    timestamp: payload.proof.timestamp
                };
                const wc = buffer_1.Buffer.alloc(4);
                wc.writeUInt32BE(message.workchain, 0);
                // const ts = Buffer.alloc(8);
                // ts.writeBigUInt64LE(BigInt(message.timestamp), 0);
                const ts = buffer_1.Buffer.alloc(8);
                const low = message.timestamp & 0xFFFFFFFF; // 低32位
                const high = Math.floor(message.timestamp / 0x100000000); // 高32位
                ts.writeUInt32LE(low, 0); // 写入低32位
                ts.writeUInt32LE(high, 4);
                const dl = buffer_1.Buffer.alloc(4);
                dl.writeUInt32LE(message.domain.lengthBytes, 0);
                // message = utf8_encode("ton-proof-item-v2/") ++
                //           Address ++
                //           AppDomain ++
                //           Timestamp ++
                //           Payload
                const msg = buffer_1.Buffer.concat([
                    buffer_1.Buffer.from(tonProofPrefix),
                    wc,
                    message.address,
                    dl,
                    buffer_1.Buffer.from(message.domain.value),
                    ts,
                    buffer_1.Buffer.from(message.payload),
                ]);
                const msgHash = buffer_1.Buffer.from(yield (0, ton_crypto_1.sha256)(msg));
                // signature = Ed25519Sign(privkey, sha256(0xffff ++ utf8_encode("ton-connect") ++ sha256(message)))
                const fullMsg = buffer_1.Buffer.concat([
                    buffer_1.Buffer.from([0xff, 0xff]),
                    buffer_1.Buffer.from(tonConnectPrefix),
                    msgHash,
                ]);
                const result = buffer_1.Buffer.from(yield (0, ton_crypto_1.sha256)(fullMsg));
                console.log(`checkProof result  ${JSON.stringify(message)}`);
                return tweetnacl_1.sign.detached.verify(result, message.signature, publicKey);
            }
            catch (e) {
                console.log(`checkProof error  ${e}`);
                return false;
            }
        });
    }
}
exports.TonProofService = TonProofService;
