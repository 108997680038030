"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Engine = void 0;
const call_for_success_1 = require("../utils/call-for-success");
const safejson_1 = require("../utils/safejson");
const log_1 = require("../utils/log");
class Engine {
    constructor(connectionManager) {
        this.pendingRequests = new Map();
        this.disconnectListener = [];
        this.connectionManager = connectionManager;
        this.connectionManager.addListener("sdk_response", (event, msg) => {
            var jsonResponse = (0, safejson_1.safeJsonParse)(msg);
            (0, log_1.logDebug)(`Engine ==>> addListener ==> success ==> ${jsonResponse}`);
            this.handleResponse(event, jsonResponse);
        }, function () {
        });
    }
    addDisconnectListener(disconnecter) {
        this.disconnectListener.push(disconnecter);
    }
    disconnect() {
        (0, log_1.logDebug)('Engine ==>> disconnect');
        this.pendingRequests.clear();
    }
    getRequestId() {
        return __awaiter(this, void 0, void 0, function* () {
            const requestId = Date.now().toString();
            (0, log_1.logDebug)('Engine ==>> getRequestId ==>', requestId);
            return requestId;
        });
    }
    bindRequestForPromise(requestId, resolve) {
        this.pendingRequests.set(requestId, resolve);
    }
    send(request, requestAnswer, option) {
        return __awaiter(this, void 0, void 0, function* () {
            const requestId = yield this.getRequestId();
            let packageId = yield (0, call_for_success_1.callForSuccess)(() => this.connectionManager.send(JSON.stringify(Object.assign(Object.assign({}, request), { requestId: requestId }))), option);
            this.connectionManager.events.once(packageId.toString(), (event) => {
                var _a;
                (0, log_1.logDebug)(`Engine ==> send => onAck => ${(0, safejson_1.safeJsonStringify)(event)}`);
                if (event && event.result) {
                    (_a = requestAnswer.onAck) === null || _a === void 0 ? void 0 : _a.call(requestAnswer);
                }
            });
            if (requestAnswer.resolve) {
                this.bindRequestForPromise(requestId, requestAnswer.resolve);
            }
        });
    }
    handleResponse(event, response) {
        (0, log_1.logDebug)(`Engine ==>> handleResponse_1  ======>response: ${(0, safejson_1.safeJsonStringify)(response)}`);
        if ("requestId" in response) {
            (0, log_1.logDebug)("Engine ==>> handleResponse_2  ===> in response");
            let requestId = response.requestId;
            const resolve = this.pendingRequests.get(requestId);
            if (resolve) {
                (0, log_1.logDebug)("Engine ==>> handleResponse_3  ===> find resolve:", resolve);
                resolve(response);
                // 从pendingRequests中删除这个Promise
                this.pendingRequests.delete(requestId);
            }
            else {
                (0, log_1.logDebug)("Engine ==>> handleResponse_3  ===> not find resolve:");
                if (response.method === "disconnect") {
                    this.disconnectListener.forEach(listener => listener());
                    this.disconnectListener = [];
                    this.disconnect();
                }
            }
        }
    }
}
exports.Engine = Engine;
