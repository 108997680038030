"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logDebug = logDebug;
exports.logError = logError;
exports.logWarning = logWarning;
const debug = false;
function logDebug(...args) {
    if (typeof console != 'undefined') {
        try {
            if (debug) {
                console.debug('[OKX_CONNECT_SDK]', ...args);
            }
        }
        catch (_a) { }
    }
}
function logError(...args) {
    if (typeof console != 'undefined') {
        try {
            console.error('[OKX_CONNECT_SDK]', ...args);
        }
        catch (_a) { }
    }
}
function logWarning(...args) {
    if (typeof console != 'undefined') {
        try {
            console.warn('[OKX_CONNECT_SDK]', ...args);
        }
        catch (_a) { }
    }
}
