"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BridgeProvider = void 0;
const bridge_connection_1 = require("./models/bridge-connection");
const bridge_connection_storage_1 = require("../bridge-connection-storage");
const url_1 = require("../../../../core/utils/url");
const log_1 = require("../../../../core/utils/log");
const connectionManager_1 = require("../../../../core/connect/connectionManager");
const version_1 = require("../../constants/version");
const engine_1 = require("../../../../core/connect/engine");
const buffer_1 = require("buffer");
const protocol_1 = require("../../../../core/protocol");
const utils_1 = require("../../../../core/utils/utils");
class BridgeProvider {
    constructor(storage) {
        this.storage = storage;
        this.type = 'http';
        this.listeners = [];
        this.connectManager = new connectionManager_1.ConnectionManager();
        this.walletConnectionSource = { "universalLink": version_1.standardUniversalLink };
        this.engine = new engine_1.Engine(this.connectManager);
        this.openUniversalLink = false;
        this.connectionStorage = new bridge_connection_storage_1.BridgeConnectionStorage(storage);
    }
    static fromStorage(storage) {
        return __awaiter(this, void 0, void 0, function* () {
            (0, log_1.logDebug)('bridge-provider ==>> fromStorage ==>>storage:', storage);
            const bridgeConnectionStorage = new bridge_connection_storage_1.BridgeConnectionStorage(storage);
            const connection = yield bridgeConnectionStorage.getHttpConnection();
            if ((0, bridge_connection_1.isPendingConnectionHttp)(connection)) {
                return null;
            }
            const provider = new BridgeProvider(storage);
            provider.openUniversalLink = connection.openUniversalLink;
            return provider;
        });
    }
    connect(message, redirect, openUniversalLink) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            (0, log_1.logDebug)('bridge-provider ==>> connect_1 ==>>message:', message);
            (0, log_1.logDebug)('bridge-provider ==>> connect_1 ==>>redirect:', redirect);
            (0, log_1.logDebug)('bridge-provider ==>> connect_1 ==>>openUniversalLink:', openUniversalLink);
            try {
                const connectInfo = this.connectManager.getConnectInfo();
                const requestId = yield this.engine.getRequestId();
                this.openUniversalLink = openUniversalLink;
                const connectRequest = {
                    protocolVer: Number(version_1.tonConnectSdkVersion),
                    topic: connectInfo.channelId,
                    clientId: connectInfo.clientId,
                    requestId: requestId,
                    dAppInfo: message.dappInfo,
                    requests: message.items,
                    redirect: redirect !== null && redirect !== void 0 ? redirect : "none",
                };
                const connectRequestStr = JSON.stringify(connectRequest);
                const base64Encoded = buffer_1.Buffer.from(connectRequestStr).toString('base64');
                const deeplinkUrl = `${version_1.standardDeeplink}?param=${base64Encoded}`;
                (0, log_1.logDebug)('bridge-provider ==>> connect_2 ==>>fullUrl:', deeplinkUrl);
                yield this.connectionStorage
                    .storeConnection({
                    type: 'http',
                    connectionSource: this.walletConnectionSource,
                    channelId: connectInfo.channelId,
                    openUniversalLink: openUniversalLink,
                });
                const isIOS = (0, utils_1.isIos)();
                if (isIOS) {
                    if (openUniversalLink !== null && openUniversalLink === true) {
                        (0, url_1.openOKXDeeplinkWithFallback)(deeplinkUrl);
                    }
                }
                yield ((_a = this.connectManager) === null || _a === void 0 ? void 0 : _a.disconnect(false));
                (_b = this.engine) === null || _b === void 0 ? void 0 : _b.bindRequestForPromise(requestId, this.connectListener.bind(this));
                (_c = this.engine) === null || _c === void 0 ? void 0 : _c.addDisconnectListener(this.disconnectListener.bind(this));
                yield this.connectManager.connect();
                if (!isIOS) {
                    if (openUniversalLink !== null && openUniversalLink === true) {
                        (0, url_1.openOKXDeeplinkWithFallback)(deeplinkUrl);
                    }
                }
                return (0, url_1.getUniversalLink)(deeplinkUrl);
            }
            catch (error) {
                console.error('Error connecting:', error);
                throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.UNKNOWN_ERROR, 'connect error');
            }
        });
    }
    listen(callback) {
        this.listeners.push(callback);
        return () => (this.listeners = this.listeners.filter(listener => listener !== callback));
    }
    disconnectListener() {
        return __awaiter(this, void 0, void 0, function* () {
            (0, log_1.logDebug)('bridge-provider ==>> disconnectListener');
            const disconnectMessage = {
                event: 'disconnect',
                requestId: Number(1),
                payload: {},
            };
            this.listeners.forEach(listener => listener(disconnectMessage));
            yield this.closeConnection();
        });
    }
    connectListener(response) {
        return __awaiter(this, void 0, void 0, function* () {
            const walletMessage = response;
            (0, log_1.logDebug)('bridge-provider ==>> connectListener_1 ==>>walletMessage:', walletMessage);
            //connect  connecterror  disconnect
            if (walletMessage.requestId !== undefined) {
                const lastId = yield this.connectionStorage.getLastWalletEventId();
                if (lastId !== undefined && Number(walletMessage.requestId) <= lastId) {
                    (0, log_1.logError)(`Received event id (=${walletMessage.requestId}) must be greater than stored last wallet event id (=${lastId}) `);
                    return;
                }
            }
            // `this.listeners` might be modified in the event handler
            const listeners = this.listeners;
            if (walletMessage.method === 'connect') {
                const tonAddrItem = walletMessage.payload.responses.find(item => item.name === 'ton_addr');
                const tonProfItem = walletMessage.payload.responses.find(item => item.name === 'ton_proof');
                const walletInfo = walletMessage.payload.wallet;
                const tonDeviceInfo = {
                    platform: walletInfo.platform,
                    appName: walletInfo.appName,
                    appVersion: walletInfo.appVersion,
                    maxProtocolVersion: walletInfo.maxProtocolVersion,
                    features: walletInfo.features.ton,
                };
                const itemArr = [];
                if (tonAddrItem) {
                    itemArr.push(tonAddrItem);
                }
                if (tonProfItem) {
                    itemArr.push(tonProfItem);
                }
                const oldStorage = yield this.connectionStorage.getConnection();
                const tonConnectInfo = {
                    type: 'http',
                    lastWalletEventId: Number(walletMessage.requestId),
                    connectEvent: {
                        event: 'connect',
                        payload: {
                            items: itemArr,
                            device: tonDeviceInfo,
                        },
                    },
                    connectionSource: this.walletConnectionSource,
                    channelId: oldStorage === null || oldStorage === void 0 ? void 0 : oldStorage.channelId,
                    openUniversalLink: oldStorage === null || oldStorage === void 0 ? void 0 : oldStorage.openUniversalLink,
                };
                (0, log_1.logDebug)('bridge-provider ==>> connectListener_2 ==>>storeConnection:', tonConnectInfo);
                yield this.connectionStorage.storeConnection(tonConnectInfo);
                const successMessage = {
                    event: 'connect',
                    requestId: Number(walletMessage.requestId),
                    payload: tonConnectInfo.connectEvent.payload,
                };
                (0, log_1.logDebug)('bridge-provider ==>> connectListener_3 ==>>listeners.forEach:', successMessage);
                listeners.forEach(listener => listener(successMessage));
            }
            else if (walletMessage.method === 'connect_error') {
                yield this.connectionStorage.storeLastWalletEventId(Number(walletMessage.requestId));
                const errorMessage = {
                    event: 'connect_error',
                    requestId: Number(walletMessage.requestId),
                    payload: walletMessage.payload,
                };
                (0, log_1.logDebug)('bridge-provider ==>> connectListener_4 ==>>connect_error:', errorMessage);
                listeners.forEach(listener => listener(errorMessage));
            }
        });
    }
    closeConnection() {
        return __awaiter(this, arguments, void 0, function* (fromRestore = false, needOpenUrl = false) {
            var _a, _b;
            (0, log_1.logDebug)('bridge-provider ==>> closeConnection ==>>fromRestore:', fromRestore, ' needOpenUrl:', needOpenUrl);
            this.listeners = [];
            if (!needOpenUrl) {
                yield ((_a = this.connectManager) === null || _a === void 0 ? void 0 : _a.disconnect(fromRestore));
            }
            if (!fromRestore) {
                (_b = this.engine) === null || _b === void 0 ? void 0 : _b.disconnect();
                yield this.connectionStorage.removeConnection();
            }
        });
    }
    restoreConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (0, log_1.logDebug)('bridge-provider ==>> restoreConnection_1');
            const storedConnection = yield this.connectionStorage.getHttpConnection();
            if (!storedConnection) {
                return;
            }
            if ((0, bridge_connection_1.isPendingConnectionHttp)(storedConnection)) {
                return;
            }
            (0, log_1.logDebug)('bridge-provider ==>> restoreConnection_2 ==>>storedConnection.channelId:', storedConnection.channelId);
            if (storedConnection.channelId) {
                try {
                    const successMessage = {
                        event: 'connect',
                        requestId: Number(0),
                        payload: storedConnection.connectEvent.payload,
                    };
                    this.listeners.forEach(listener => listener(successMessage));
                    const channelId = storedConnection.channelId;
                    (0, log_1.logDebug)('bridge-provider ==>> restoreConnection_3 ==>>this.connectManager.restoreConnect:', channelId);
                    (_a = this.engine) === null || _a === void 0 ? void 0 : _a.addDisconnectListener(this.disconnectListener.bind(this));
                    yield this.connectManager.restoreConnect(channelId);
                }
                catch (e) {
                    (0, log_1.logDebug)('bridge-provider ==>> restoreConnection_4 ==>>closeConnection_1');
                    yield this.closeConnection();
                }
            }
            else {
                (0, log_1.logDebug)('bridge-provider ==>> restoreConnection_4 ==>>closeConnection_2');
                yield this.closeConnection();
            }
        });
    }
    openOKXWallet() {
        (0, log_1.logDebug)('bridge-provider ==>> openOKXWallet', this.openUniversalLink);
        if (this.openUniversalLink) {
            (0, url_1.openOKXDeeplinkWithFallback)(version_1.standardDeeplink);
        }
    }
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            (0, log_1.logDebug)('bridge-provider ==>> disconnect_1');
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                let called = false;
                const onRequestSent = () => {
                    if (!called) {
                        called = true;
                        this.closeConnection().then(resolve);
                    }
                };
                try {
                    yield this.sendRequest({ method: 'disconnect' }, { onRequestSent: onRequestSent, attempts: 1 });
                }
                catch (e) {
                    if (!called) {
                        this.closeConnection().then(resolve);
                    }
                }
                finally {
                    onRequestSent();
                }
            }));
        });
    }
    sendRequest(request, options) {
        (0, log_1.logDebug)('bridge-provider ==>> sendRequest');
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            try {
                yield ((_a = this.engine) === null || _a === void 0 ? void 0 : _a.send(request, {
                    resolve: function (response) {
                        resolve(response);
                    },
                    onAck: function () {
                        var _a;
                        (_a = options === null || options === void 0 ? void 0 : options.onRequestSent) === null || _a === void 0 ? void 0 : _a.call(options);
                    }
                }, { attempts: options === null || options === void 0 ? void 0 : options.attempts }));
            }
            catch (e) {
                reject(e);
            }
        }));
    }
}
exports.BridgeProvider = BridgeProvider;
