"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TonConnectTracker = void 0;
const types_1 = require("./types");
const browser_event_dispatcher_1 = require("./browser-event-dispatcher");
/**
 * Tracker for TonConnect user actions, such as transaction signing, connection, etc.
 *
 * List of events:
 *  * `connection-started`: when a user starts connecting a wallet.
 *  * `connection-completed`: when a user successfully connected a wallet.
 *  * `connection-error`: when a user cancels a connection or there is an error during the connection process.
 *  * `connection-restoring-started`: when the dApp starts restoring a connection.
 *  * `connection-restoring-completed`: when the dApp successfully restores a connection.
 *  * `connection-restoring-error`: when the dApp fails to restore a connection.
 *  * `disconnection`: when a user starts disconnecting a wallet.
 *  * `transaction-sent-for-signature`: when a user sends a transaction for signature.
 *  * `transaction-signed`: when a user successfully signs a transaction.
 *  * `transaction-signing-failed`: when a user cancels transaction signing or there is an error during the signing process.
 *
 * If you want to track user actions, you can subscribe to the window events with prefix `ton-connect-`:
 *
 * @example
 * window.addEventListener('ton-connect-transaction-sent-for-signature', (event) => {
 *    logDebug('Transaction init', event.detail);
 * });
 *
 * @internal
 */
class TonConnectTracker {
    /**
     * Version of the library.
     */
    get version() {
        return (0, types_1.createVersionInfo)({
            ton_connect_sdk_lib: this.tonConnectSdkVersion,
        });
    }
    constructor(options) {
        /**
         * Event prefix for user actions.
         * @private
         */
        this.eventPrefix = 'ton-connect-';
        this.eventDispatcher = new browser_event_dispatcher_1.BrowserEventDispatcher();
        this.tonConnectSdkVersion = options.tonConnectSdkVersion;
    }
    /**
     * Emit user action event to the window.
     * @param eventDetails
     * @private
     */
    dispatchUserActionEvent(eventDetails) {
        try {
            this.eventDispatcher
                .dispatchEvent(`${this.eventPrefix}${eventDetails.type}`, eventDetails)
                .catch();
        }
        catch (e) { }
    }
    /**
     * Track connection init event.
     * @param args
     */
    trackConnectionStarted(...args) {
        try {
            const event = (0, types_1.createConnectionStartedEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track connection connecting event.
     * @param args
     */
    trackConnectionDeepLink(...args) {
        try {
            const event = (0, types_1.createConnectionDeepLinkEvent)(...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track connection success event.
     * @param args
     */
    trackConnectionCompleted(...args) {
        try {
            const event = (0, types_1.createConnectionCompletedEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track connection error event.
     * @param args
     */
    trackConnectionError(...args) {
        try {
            const event = (0, types_1.createConnectionErrorEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track connection restoring init event.
     * @param args
     */
    trackConnectionRestoringStarted(...args) {
        try {
            const event = (0, types_1.createConnectionRestoringStartedEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track connection restoring success event.
     * @param args
     */
    trackConnectionRestoringCompleted(...args) {
        try {
            const event = (0, types_1.createConnectionRestoringCompletedEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track connection restoring error event.
     * @param args
     */
    trackConnectionRestoringError(...args) {
        try {
            const event = (0, types_1.createConnectionRestoringErrorEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track disconnect event.
     * @param args
     */
    trackDisconnection(...args) {
        try {
            const event = (0, types_1.createDisconnectionEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track transaction init event.
     * @param args
     */
    trackTransactionSentForSignature(...args) {
        try {
            const event = (0, types_1.createTransactionSentForSignatureEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track transaction signed event.
     * @param args
     */
    trackTransactionSigned(...args) {
        try {
            const event = (0, types_1.createTransactionSignedEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
    /**
     * Track transaction error event.
     * @param args
     */
    trackTransactionSigningFailed(...args) {
        try {
            const event = (0, types_1.createTransactionSigningFailedEvent)(this.version, ...args);
            this.dispatchUserActionEvent(event);
        }
        catch (e) { }
    }
}
exports.TonConnectTracker = TonConnectTracker;
