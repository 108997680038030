"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OKXTonConnect = void 0;
const bridge_provider_1 = require("./provider/bridge/bridge-provider");
const feature_support_1 = require("./utils/feature-support");
const ton_connect_tracker_1 = require("./tracker/ton-connect-tracker");
const send_transaction_parser_1 = require("./parsers/send-transaction-parser");
const version_1 = require("./constants/version");
const bridge_connection_storage_1 = require("./provider/bridge-connection-storage");
const log_1 = require("../../core/utils/log");
const protocol_1 = require("../../core/protocol");
const storage_1 = require("../../core/connect/storage");
const storageconstants_1 = require("../../core/connect/storageconstants");
const utils_1 = require("../../core/utils/utils");
class OKXTonConnect {
    constructor(options) {
        this._wallet = null;
        this.provider = null;
        this.statusChangeSubscriptions = [];
        this.statusChangeErrorSubscriptions = [];
        this.storage = new storage_1.Storage(storageconstants_1.OKXCONNECTOR_TON_KEY);
        if (!options.metaData) {
            throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.UNKNOWN_ERROR, 'dapp metaData can not be null');
        }
        this.dappSettings = {
            metaData: Object.assign(Object.assign({}, options.metaData), { url: window.location.hostname }),
        };
        this.tracker = new ton_connect_tracker_1.TonConnectTracker({
            tonConnectSdkVersion: version_1.tonConnectSdkVersion
        });
        this.bridgeConnectionStorage = new bridge_connection_storage_1.BridgeConnectionStorage(this.storage);
    }
    /**
     * Shows if the wallet is connected right now.
     */
    get connected() {
        return this._wallet !== null;
    }
    /**
     * Current connected account or null if no account is connected.
     */
    get account() {
        var _a;
        return ((_a = this._wallet) === null || _a === void 0 ? void 0 : _a.account) || null;
    }
    /**
     * Current connected wallet or null if no account is connected.
     */
    get wallet() {
        return this._wallet;
    }
    set wallet(value) {
        this._wallet = value;
        this.statusChangeSubscriptions.forEach(callback => callback(this._wallet));
    }
    connect(request) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (0, log_1.logDebug)('ton-Connect ==>> connect_1 >> request:', request);
            if (this.connected) {
                throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.ALREADY_CONNECTED_ERROR, "Connected Wallet");
            }
            yield ((_a = this.provider) === null || _a === void 0 ? void 0 : _a.closeConnection(false, true));
            this.provider = new bridge_provider_1.BridgeProvider(this.storage);
            this.provider.listen(this.walletEventsListener.bind(this));
            this.tracker.trackConnectionStarted();
            return yield this.provider.connect(this.createConnectRequest(request), request === null || request === void 0 ? void 0 : request.redirect, request === null || request === void 0 ? void 0 : request.openUniversalLink);
        });
    }
    walletEventsListener(e) {
        (0, log_1.logDebug)('ton-Connect ==>> walletEventsListener_1 >> e:', e);
        switch (e.event) {
            case 'connect':
                this.onWalletConnected(e.payload);
                break;
            case 'connect_error':
                this.onWalletConnectError(e.payload);
                break;
            case 'disconnect':
                this.onWalletDisconnected('wallet');
        }
    }
    onWalletConnected(connectEvent) {
        (0, log_1.logDebug)('ton-Connect ==>> onWalletConnected_1 >> connectEvent:', connectEvent);
        const tonAccountItem = connectEvent.items.find(item => item.name === 'ton_addr');
        const tonProofItem = connectEvent.items.find(item => item.name === 'ton_proof');
        if (!tonAccountItem) {
            throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.UNKNOWN_ERROR, 'ton_addr connection item was not found');
        }
        const wallet = {
            device: connectEvent.device,
            provider: this.provider.type,
            account: {
                address: tonAccountItem.address,
                chain: tonAccountItem.network,
                walletStateInit: tonAccountItem.walletStateInit,
                publicKey: tonAccountItem.publicKey
            }
        };
        if (tonProofItem) {
            wallet.connectItems = {
                tonProof: tonProofItem
            };
        }
        this.wallet = wallet;
        (0, log_1.logDebug)('ton-Connect ==>> onWalletConnected_2 >> wallet:', wallet);
        this.tracker.trackConnectionCompleted(wallet);
    }
    onWalletConnectError(connectEventError) {
        var _a;
        (0, log_1.logDebug)('ton-Connect ==>> onWalletConnectError_1 >> connectEventError:', connectEventError);
        const error = new protocol_1.OkxConnectError(connectEventError.code, connectEventError.message);
        (0, log_1.logDebug)('ton-Connect ==>> onWalletConnectError_1 >> error:', error);
        this.statusChangeErrorSubscriptions.forEach(errorsHandler => errorsHandler(error));
        this.tracker.trackConnectionError((_a = connectEventError.message) !== null && _a !== void 0 ? _a : "", connectEventError.code);
    }
    onWalletDisconnected(scope) {
        this.tracker.trackDisconnection(this.wallet, scope);
        this.wallet = null;
    }
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (0, log_1.logDebug)('ton-Connect ==>> disconnect_1');
            if (!this.connected) {
                throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.NOT_CONNECTED_ERROR, 'wallet is not connected');
            }
            (0, log_1.logDebug)('ton-Connect ==>> disconnect_222');
            this.onWalletDisconnected('dapp');
            yield ((_a = this.provider) === null || _a === void 0 ? void 0 : _a.disconnect());
        });
    }
    onStatusChange(callback, errorsHandler) {
        this.statusChangeSubscriptions.push(callback);
        if (errorsHandler) {
            this.statusChangeErrorSubscriptions.push(errorsHandler);
        }
        return () => {
            this.statusChangeSubscriptions = this.statusChangeSubscriptions.filter(item => item !== callback);
            if (errorsHandler) {
                this.statusChangeErrorSubscriptions = this.statusChangeErrorSubscriptions.filter(item => item !== errorsHandler);
            }
        };
    }
    restoreConnection() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (0, log_1.logDebug)('ton-Connect ==>> restoreConnection_1');
            this.tracker.trackConnectionRestoringStarted();
            let provider = null;
            try {
                provider = yield bridge_provider_1.BridgeProvider.fromStorage(this.storage);
            }
            catch (_b) {
                this.tracker.trackConnectionRestoringError('Provider is not restored');
                yield this.bridgeConnectionStorage.removeConnection();
                provider === null || provider === void 0 ? void 0 : provider.closeConnection();
                provider = null;
                return;
            }
            if (!provider) {
                (0, log_1.logError)('Provider is not restored');
                this.tracker.trackConnectionRestoringError('Provider is not restored');
                return;
            }
            (0, log_1.logDebug)('ton-Connect ==>> restoreConnection_2 ==>> Provider is restored ');
            (_a = this.provider) === null || _a === void 0 ? void 0 : _a.closeConnection(true, false);
            this.provider = provider;
            provider.listen(this.walletEventsListener.bind(this));
            yield this.provider.restoreConnection();
            if (this.connected) {
                this.tracker.trackConnectionRestoringCompleted(this.wallet);
            }
            else {
                this.tracker.trackConnectionRestoringError('Connection restoring failed');
            }
        });
    }
    sendTransaction(transaction, options) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (0, log_1.logDebug)('ton-Connect ==>> sendTransaction_1', transaction);
            this.checkConnection();
            (0, feature_support_1.checkSendTransactionSupport)(this.wallet.device.features, {
                requiredMessagesNumber: transaction.messages.length
            });
            this.tracker.trackTransactionSentForSignature(this.wallet, transaction);
            (0, log_1.logDebug)('ton-Connect ==>> sendTransaction_2 ==>> check params');
            const { validUntil } = transaction, tx = __rest(transaction, ["validUntil"]);
            const from = transaction.from || this.account.address;
            let network = this.account.chain;
            (0, log_1.logDebug)('ton-Connect ==>> sendTransaction_2 ==>> await sendRequest', network);
            if ((0, utils_1.isIos)()) {
                (_a = this.provider) === null || _a === void 0 ? void 0 : _a.openOKXWallet();
            }
            const response = yield this.provider.sendRequest(send_transaction_parser_1.sendTransactionParser.convertToRpcRequest(Object.assign(Object.assign({}, tx), { valid_until: validUntil, from,
                network })), {
                onRequestSent: () => {
                    var _a, _b;
                    if (!(0, utils_1.isIos)()) {
                        (_a = this.provider) === null || _a === void 0 ? void 0 : _a.openOKXWallet();
                    }
                    (_b = options === null || options === void 0 ? void 0 : options.onRequestSent) === null || _b === void 0 ? void 0 : _b.call(options);
                }
            });
            (0, log_1.logDebug)('ton-Connect ==>> sendTransaction_3 ==>>sendRequest response:', response);
            if (send_transaction_parser_1.sendTransactionParser.isError(response)) {
                this.tracker.trackTransactionSigningFailed(this.wallet, transaction, response.error.message, response.error.code);
                throw new protocol_1.OkxConnectError(response.error.code, response.error.message);
            }
            const result = send_transaction_parser_1.sendTransactionParser.convertFromRpcResponse(response);
            this.tracker.trackTransactionSigned(this.wallet, transaction, result);
            return result;
        });
    }
    checkConnection() {
        (0, log_1.logDebug)('ton-Connect ==>> checkConnection');
        if (!this.connected) {
            throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.NOT_CONNECTED_ERROR, "Wallet not connected");
        }
    }
    createConnectRequest(request) {
        (0, log_1.logDebug)('ton-Connect ==>> createConnectRequest');
        const items = [
            {
                name: 'ton_addr'
            }
        ];
        if (request === null || request === void 0 ? void 0 : request.tonProof) {
            items.push({
                name: 'ton_proof',
                payload: request.tonProof
            });
        }
        return {
            dappInfo: this.dappSettings.metaData,
            items: items
        };
    }
}
exports.OKXTonConnect = OKXTonConnect;
