"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendTransactionParser = void 0;
const rpc_parser_1 = require("./rpc-parser");
class SendTransactionParser extends rpc_parser_1.RpcParser {
    convertToRpcRequest(request) {
        return {
            method: 'ton_sendTransaction',
            params: request
        };
    }
    convertFromRpcResponse(rpcResponse) {
        return {
            boc: rpcResponse.result.toString()
        };
    }
}
exports.sendTransactionParser = new SendTransactionParser();
