"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserEventDispatcher = void 0;
/**
 * A concrete implementation of EventDispatcher that dispatches events to the browser window.
 */
class BrowserEventDispatcher {
    constructor() {
        /**
         * The window object, possibly undefined in a server environment.
         * @private
         */
        this.window = window;
    }
    /**
     * Dispatches an event with the given name and details to the browser window.
     * @param eventName - The name of the event to dispatch.
     * @param eventDetails - The details of the event to dispatch.
     * @returns A promise that resolves when the event has been dispatched.
     */
    dispatchEvent(eventName, eventDetails) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const event = new CustomEvent(eventName, { detail: eventDetails });
            (_a = this.window) === null || _a === void 0 ? void 0 : _a.dispatchEvent(event);
        });
    }
    /**
     * Adds an event listener to the browser window.
     * @param eventName - The name of the event to listen for.
     * @param listener - The listener to add.
     * @param options - The options for the listener.
     * @returns A function that removes the listener.
     */
    addEventListener(eventName, listener, options) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            (_a = this.window) === null || _a === void 0 ? void 0 : _a.addEventListener(eventName, listener, options);
            return () => {
                var _a;
                return (_a = this.window) === null || _a === void 0 ? void 0 : _a.removeEventListener(eventName, listener);
            };
        });
    }
}
exports.BrowserEventDispatcher = BrowserEventDispatcher;
