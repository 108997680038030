"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISCONNECT_ERROR_CODES = void 0;
var DISCONNECT_ERROR_CODES;
(function (DISCONNECT_ERROR_CODES) {
    DISCONNECT_ERROR_CODES[DISCONNECT_ERROR_CODES["UNKNOWN_ERROR"] = 0] = "UNKNOWN_ERROR";
    DISCONNECT_ERROR_CODES[DISCONNECT_ERROR_CODES["BAD_REQUEST_ERROR"] = 1] = "BAD_REQUEST_ERROR";
    DISCONNECT_ERROR_CODES[DISCONNECT_ERROR_CODES["UNKNOWN_APP_ERROR"] = 100] = "UNKNOWN_APP_ERROR";
    DISCONNECT_ERROR_CODES[DISCONNECT_ERROR_CODES["METHOD_NOT_SUPPORTED"] = 400] = "METHOD_NOT_SUPPORTED";
})(DISCONNECT_ERROR_CODES || (exports.DISCONNECT_ERROR_CODES = DISCONNECT_ERROR_CODES = {}));
