"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OKX_CONNECT_ERROR_CODES = exports.OkxConnectError = void 0;
/**
 * Base class for TonConnect errors. You can check if the error was triggered by the using `err instanceof TonConnectError`.
 */
class OkxConnectError extends Error {
    constructor(code, message) {
        if (message == undefined) {
            message = getDefaultMessageByCode(code);
        }
        super(message);
        this.code = code;
        this.message = `${OkxConnectError.prefix} ${message ? '\n' + message : ''}`;
        Object.setPrototypeOf(this, OkxConnectError.prototype);
    }
}
exports.OkxConnectError = OkxConnectError;
OkxConnectError.prefix = '[OKX_CONNECT_SDK_ERROR]';
function getDefaultMessageByCode(code) {
    if (code == OKX_CONNECT_ERROR_CODES.UNKNOWN_ERROR) {
        return "unknown error";
    }
    else if (code == OKX_CONNECT_ERROR_CODES.BAD_REQUEST_ERROR) {
        return "bad request error";
    }
    else if (code == OKX_CONNECT_ERROR_CODES.ALREADY_CONNECTED_ERROR) {
        return "already connected error";
    }
    else if (code == OKX_CONNECT_ERROR_CODES.NOT_CONNECTED_ERROR) {
        return "not connected error";
    }
    else if (code == OKX_CONNECT_ERROR_CODES.UNKNOWN_APP_ERROR) {
        return "unknown app error";
    }
    else if (code == OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR) {
        return "user rejects error";
    }
    else if (code == OKX_CONNECT_ERROR_CODES.METHOD_NOT_SUPPORTED) {
        return "method not supported";
    }
    return "unknown error";
}
var OKX_CONNECT_ERROR_CODES;
(function (OKX_CONNECT_ERROR_CODES) {
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["UNKNOWN_ERROR"] = 0] = "UNKNOWN_ERROR";
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["BAD_REQUEST_ERROR"] = 1] = "BAD_REQUEST_ERROR";
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["ALREADY_CONNECTED_ERROR"] = 11] = "ALREADY_CONNECTED_ERROR";
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["NOT_CONNECTED_ERROR"] = 12] = "NOT_CONNECTED_ERROR";
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["UNKNOWN_APP_ERROR"] = 100] = "UNKNOWN_APP_ERROR";
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["USER_REJECTS_ERROR"] = 300] = "USER_REJECTS_ERROR";
    OKX_CONNECT_ERROR_CODES[OKX_CONNECT_ERROR_CODES["METHOD_NOT_SUPPORTED"] = 400] = "METHOD_NOT_SUPPORTED";
})(OKX_CONNECT_ERROR_CODES || (exports.OKX_CONNECT_ERROR_CODES = OKX_CONNECT_ERROR_CODES = {}));
