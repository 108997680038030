"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkSendTransactionSupport = checkSendTransactionSupport;
const protocol_1 = require("../../../core/protocol");
function checkSendTransactionSupport(features, options) {
    const supportsDeprecatedSendTransactionFeature = features.includes('ton_sendTransaction');
    if (!supportsDeprecatedSendTransactionFeature) {
        throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.METHOD_NOT_SUPPORTED, "Wallet doesn't support SendTransaction feature.");
    }
    if (4 < options.requiredMessagesNumber) {
        throw new protocol_1.OkxConnectError(protocol_1.OKX_CONNECT_ERROR_CODES.METHOD_NOT_SUPPORTED, `Wallet is not able to handle such SendTransaction request. Max support messages number is ${4}, but ${options.requiredMessagesNumber} is required.`);
    }
}
