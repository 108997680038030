"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OKX_TON_CONNECTION_AND_TRANSACTION_EVENT = void 0;
var OKX_TON_CONNECTION_AND_TRANSACTION_EVENT;
(function (OKX_TON_CONNECTION_AND_TRANSACTION_EVENT) {
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_CONNECTION_STARTED"] = "ton-connect-connection-started";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_CONNECTION_COMPLETED"] = "ton-connect-connection-completed";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_CONNECTION_ERROR"] = "ton-connect-connection-error";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_CONNECTION_RESTORING_STARTED"] = "ton-connect-connection-restoring-started";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_CONNECTION_RESTORING_COMPLETED"] = "ton-connect-connection-restoring-completed";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_CONNECTION_RESTORING_ERROR"] = "ton-connect-connection-restoring-error";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_DISCONNECTION"] = "ton-connect-disconnection";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_TRANSACTION_SENT_FOR_SIGNATURE"] = "ton-connect-transaction-sent-for-signature";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_TRANSACTION_SIGNED"] = "ton-connect-transaction-signed";
    OKX_TON_CONNECTION_AND_TRANSACTION_EVENT["OKX_TON_TRANSACTION_SIGNING_FAILED"] = "ton-connect-transaction-signing-failed";
})(OKX_TON_CONNECTION_AND_TRANSACTION_EVENT || (exports.OKX_TON_CONNECTION_AND_TRANSACTION_EVENT = OKX_TON_CONNECTION_AND_TRANSACTION_EVENT = {}));
