"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHAIN = exports.OKX_TON_CONNECTION_AND_TRANSACTION_EVENT = exports.OKX_CONNECT_ERROR_CODES = exports.OkxConnectError = exports.OKXTonConnect = void 0;
var ton_connect_1 = require("./ton/tonsdk/ton-connect");
Object.defineProperty(exports, "OKXTonConnect", { enumerable: true, get: function () { return ton_connect_1.OKXTonConnect; } });
var protocol_1 = require("./core/protocol");
Object.defineProperty(exports, "OkxConnectError", { enumerable: true, get: function () { return protocol_1.OkxConnectError; } });
Object.defineProperty(exports, "OKX_CONNECT_ERROR_CODES", { enumerable: true, get: function () { return protocol_1.OKX_CONNECT_ERROR_CODES; } });
var tonsdk_1 = require("./ton/tonsdk");
Object.defineProperty(exports, "OKX_TON_CONNECTION_AND_TRANSACTION_EVENT", { enumerable: true, get: function () { return tonsdk_1.OKX_TON_CONNECTION_AND_TRANSACTION_EVENT; } });
var tonsdk_2 = require("./ton/tonsdk");
Object.defineProperty(exports, "CHAIN", { enumerable: true, get: function () { return tonsdk_2.CHAIN; } });
