"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createVersionInfo = createVersionInfo;
exports.createConnectionStartedEvent = createConnectionStartedEvent;
exports.createConnectionDeepLinkEvent = createConnectionDeepLinkEvent;
exports.createConnectionCompletedEvent = createConnectionCompletedEvent;
exports.createConnectionErrorEvent = createConnectionErrorEvent;
exports.createConnectionRestoringStartedEvent = createConnectionRestoringStartedEvent;
exports.createConnectionRestoringCompletedEvent = createConnectionRestoringCompletedEvent;
exports.createConnectionRestoringErrorEvent = createConnectionRestoringErrorEvent;
exports.createTransactionSentForSignatureEvent = createTransactionSentForSignatureEvent;
exports.createTransactionSignedEvent = createTransactionSignedEvent;
exports.createTransactionSigningFailedEvent = createTransactionSigningFailedEvent;
exports.createDisconnectionEvent = createDisconnectionEvent;
/**
 * Create a version info.
 * @param version
 */
function createVersionInfo(version) {
    return {
        ton_connect_sdk_lib: version.ton_connect_sdk_lib
    };
}
function createConnectionInfo(version, wallet) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const isTonProof = ((_a = wallet === null || wallet === void 0 ? void 0 : wallet.connectItems) === null || _a === void 0 ? void 0 : _a.tonProof) && 'proof' in wallet.connectItems.tonProof;
    const authType = isTonProof ? 'ton_proof' : 'ton_addr';
    return {
        wallet_address: (_c = (_b = wallet === null || wallet === void 0 ? void 0 : wallet.account) === null || _b === void 0 ? void 0 : _b.address) !== null && _c !== void 0 ? _c : null,
        wallet_type: (_d = wallet === null || wallet === void 0 ? void 0 : wallet.device.appName) !== null && _d !== void 0 ? _d : null,
        wallet_version: (_e = wallet === null || wallet === void 0 ? void 0 : wallet.device.appVersion) !== null && _e !== void 0 ? _e : null,
        auth_type: authType,
        custom_data: Object.assign({ chain_id: (_g = (_f = wallet === null || wallet === void 0 ? void 0 : wallet.account) === null || _f === void 0 ? void 0 : _f.chain) !== null && _g !== void 0 ? _g : null, provider: (_h = wallet === null || wallet === void 0 ? void 0 : wallet.provider) !== null && _h !== void 0 ? _h : null }, createVersionInfo(version))
    };
}
/**
 * Create a connection init event.
 */
function createConnectionStartedEvent(version) {
    return {
        type: 'connection-started',
        custom_data: createVersionInfo(version)
    };
}
/**
 * Create a connection init event.
 */
function createConnectionDeepLinkEvent(deeplinkUrl) {
    return {
        type: 'connection-get-deeplink',
        deeplinkUrl: deeplinkUrl,
    };
}
/**
 * Create a connection completed event.
 * @param version
 * @param wallet
 */
function createConnectionCompletedEvent(version, wallet) {
    return Object.assign({ type: 'connection-completed', is_success: true }, createConnectionInfo(version, wallet));
}
/**
 * Create a connection error event.
 * @param version
 * @param error_message
 * @param errorCode
 */
function createConnectionErrorEvent(version, error_message, errorCode) {
    return {
        type: 'connection-error',
        is_success: false,
        error_message: error_message,
        error_code: errorCode !== null && errorCode !== void 0 ? errorCode : null,
        custom_data: createVersionInfo(version)
    };
}
/**
 * Create a connection restoring started event.
 */
function createConnectionRestoringStartedEvent(version) {
    return {
        type: 'connection-restoring-started',
        custom_data: createVersionInfo(version)
    };
}
/**
 * Create a connection restoring completed event.
 * @param version
 * @param wallet
 */
function createConnectionRestoringCompletedEvent(version, wallet) {
    return Object.assign({ type: 'connection-restoring-completed', is_success: true }, createConnectionInfo(version, wallet));
}
/**
 * Create a connection restoring error event.
 * @param version
 * @param errorMessage
 */
function createConnectionRestoringErrorEvent(version, errorMessage) {
    return {
        type: 'connection-restoring-error',
        is_success: false,
        error_message: errorMessage,
        custom_data: createVersionInfo(version)
    };
}
function createTransactionInfo(wallet, transaction) {
    var _a, _b, _c, _d;
    return {
        valid_until: (_a = String(transaction.validUntil)) !== null && _a !== void 0 ? _a : null,
        from: (_d = (_b = transaction.from) !== null && _b !== void 0 ? _b : (_c = wallet === null || wallet === void 0 ? void 0 : wallet.account) === null || _c === void 0 ? void 0 : _c.address) !== null && _d !== void 0 ? _d : null,
        messages: transaction.messages.map(message => {
            var _a, _b;
            return ({
                address: (_a = message.address) !== null && _a !== void 0 ? _a : null,
                amount: (_b = message.amount) !== null && _b !== void 0 ? _b : null
            });
        })
    };
}
/**
 * Create a transaction init event.
 * @param version
 * @param wallet
 * @param transaction
 */
function createTransactionSentForSignatureEvent(version, wallet, transaction) {
    return Object.assign(Object.assign({ type: 'transaction-sent-for-signature' }, createConnectionInfo(version, wallet)), createTransactionInfo(wallet, transaction));
}
/**
 * Create a transaction signed event.
 * @param version
 * @param wallet
 * @param transaction
 * @param signedTransaction
 */
function createTransactionSignedEvent(version, wallet, transaction, signedTransaction) {
    return Object.assign(Object.assign({ type: 'transaction-signed', is_success: true, signed_transaction: signedTransaction.boc }, createConnectionInfo(version, wallet)), createTransactionInfo(wallet, transaction));
}
/**
 * Create a transaction error event.
 * @param version
 * @param wallet
 * @param transaction
 * @param errorMessage
 * @param errorCode
 */
function createTransactionSigningFailedEvent(version, wallet, transaction, errorMessage, errorCode) {
    return Object.assign(Object.assign({ type: 'transaction-signing-failed', is_success: false, error_message: errorMessage, error_code: errorCode !== null && errorCode !== void 0 ? errorCode : null }, createConnectionInfo(version, wallet)), createTransactionInfo(wallet, transaction));
}
/**
 * Create a disconnect event.
 * @param version
 * @param wallet
 * @param scope
 * @returns
 */
function createDisconnectionEvent(version, wallet, scope) {
    return Object.assign({ type: 'disconnection', scope: scope }, createConnectionInfo(version, wallet));
}
